import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../../../components/Seo';
import { getFeaturedImageDataFromWpPost, getDefaultSEO } from '../../../utils';
import { CCGuidesHubContainer } from '../../../containers/CCGuidesHubContainer';
import { ICreditCardGuidesPageProps } from '../../../components/ContentTypes/HubPages/CreditCardsGuides.def';
import HTPageWrapper from '../../../wrappers/HTPageWrapper';

const CreditCardGuidesHubPage = ({ data, location }: PageProps<ICreditCardGuidesPageProps>) => {
  const {
    creditCardGuidesPage,
    businessCreditCardGuidesDynamic,
    topCreditCardGuidesDynamic,
    latestCreditCardGuide,
  } = data;

  return (
    <HTPageWrapper category="Hub Page, Guides" title="Credit Card Guides" location={location}>
      <CCGuidesHubContainer
        creditCardGuidesPage={creditCardGuidesPage}
        businessCreditCardGuidesDynamic={businessCreditCardGuidesDynamic}
        topCreditCardGuidesDynamic={topCreditCardGuidesDynamic}
        latestCreditCardGuide={latestCreditCardGuide}
      />
    </HTPageWrapper>
  );
};

export default CreditCardGuidesHubPage;

export const Head = ({ location, data }: PageProps<ICreditCardGuidesPageProps>) => {
  const { latestCreditCardGuide } = data;
  const featuredArticleExtracted = latestCreditCardGuide.nodes[0];

  const pageTitle = 'Browse Credit Card Guides from UpgradedPoints.com';
  const socialTitle = pageTitle;
  const ogDescription =
    'Our experts provide detailed guides on everything from upgraded your card to getting the most points from your purchases.';
  const includeCollectionPageType = true;
  const extraBreadcrumbList = [
    { name: 'Credit Cards', slug: 'credit-cards' },
    { name: 'Credit Card Guides', slug: 'guides' },
  ];
  const featuredPost = featuredArticleExtracted?.featuredImage;

  const featuredImageData = featuredPost
    ? getFeaturedImageDataFromWpPost(featuredArticleExtracted)
    : null;
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  return <Seo title={pageTitle} wpSeo={seo} featuredImageData={featuredImageData} />;
};

export const query = graphql`
  query CreditCardGuidesQuery {
    topCreditCardGuidesDynamic: allWpPost(
      filter: {
        tags: {
          nodes: {
            elemMatch: {
              slug: { in: ["credit-card-guides-business", "credit-card-guides-personal"] }
            }
          }
        }
      }
      limit: 6
      sort: { date: DESC }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        title
        uri
      }
    }
    businessCreditCardGuidesDynamic: allWpPost(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: "credit-card-guides-business" } } } } }
      limit: 6
      sort: { date: DESC }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        title
        uri
      }
    }
    latestCreditCardGuide: allWpPost(
      filter: {
        tags: {
          nodes: {
            elemMatch: {
              slug: { in: ["credit-card-guides-business", "credit-card-guides-personal"] }
            }
          }
        }
      }
      limit: 1
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        title
        uri
      }
    }
    creditCardGuidesPage: wpPage(uri: { eq: "/credit-cards/guides/" }) {
      ccGuidesByIssuer {
        issuerTitle
        viewAllIssuerText
        viewAllIssuerLink
        issuerBoxes {
          issuerLink {
            ... on WpPost {
              uri
            }
          }
          issuerIcon {
            sourceUrl
            height
            width
            altText
          }
        }
      }
      ccGuidesByType {
        ccGuidesTypeTitle
        ccGuidesTypeViewAllText
        ccGuidesTypeViewAllLink
        ccGuidesTypeFeaturedTitle
        ccGuidesTypeFeaturedText
        ccGuidesTypeFeaturedLink
        ccGuidesTypeBoxItems {
          ccTypeItemIcon {
            sourceUrl
            height
            width
          }
          ccTypeItemLink
          ccTypeItemTitle
        }
      }
      ccGuides {
        ccLink
        ccLinkText
        ccSubtitle
        ccTitle
        ccGuideDisplayArticles
        ccGuideArticles {
          ... on WpPost {
            featuredImage {
              node {
                sourceUrl
              }
            }
            title
            uri
          }
        }
      }
      categoryNavFields {
        categoriesAll {
          title
          subTitle
          link
          text
        }
      }
      popularGuides {
        popularTitle
        featuredPopularCategory
        featuredPopularLink
        featuredPost {
          ... on WpPost {
            featuredImage {
              node {
                sourceUrl
                height
                width
              }
            }
            title
            uri
          }
        }
        popularArticles {
          ... on WpPost {
            featuredImage {
              node {
                sourceUrl
              }
            }
            title
            uri
          }
        }
      }
    }
  }
`;
