import React from 'react';
import CreditCardGuides from '../components/CreditCards/components/CreditCardGuides/CreditCardGuides';
import { IArticleExcerpt } from '../components/ContentTypes/Article/Article.def';
import { ICreditCardGuidesPageProps } from '../components/ContentTypes/HubPages/CreditCardsGuides.def';
import { getArticles, getPlaceholderGatsbyImg } from '../utils';
import { replaceDomainWithSiteDomain } from '../utils/helpers/replaceDomain';

const defaultWidth = 50
const defaultHeight = 50

export const CCGuidesHubContainer = ({
  creditCardGuidesPage,
  businessCreditCardGuidesDynamic,
  topCreditCardGuidesDynamic,
  latestCreditCardGuide,
}: ICreditCardGuidesPageProps) => {
  let topCreditCardGuidesPostsDynamic: Queries.WpPost[] = [];
  let businessCreditCardGuidesPostsDynamic: Queries.WpPost[] = [];
  let latestCreditCardGuidesPost: Queries.WpPost[] = [];

  if (topCreditCardGuidesDynamic && Array.isArray(topCreditCardGuidesDynamic.nodes)) {
    topCreditCardGuidesPostsDynamic = topCreditCardGuidesDynamic.nodes;
  }
  if (businessCreditCardGuidesDynamic && Array.isArray(businessCreditCardGuidesDynamic.nodes)) {
    businessCreditCardGuidesPostsDynamic = businessCreditCardGuidesDynamic.nodes;
  }
  if (latestCreditCardGuide && Array.isArray(latestCreditCardGuide.nodes)) {
    latestCreditCardGuidesPost = latestCreditCardGuide.nodes;
  }

  const topCreditCardGuidesDynamicArticles = getArticles(topCreditCardGuidesPostsDynamic).map(
    (article) => ({
      ...article,
      imageOverlay: true,
    })
  );
  const businessCreditCardGuidesDynamicArticles = getArticles(
    businessCreditCardGuidesPostsDynamic
  ).map((article) => ({
    ...article,
    imageOverlay: true,
  }));

  let latestPopularArticle: IArticleExcerpt = {
    link: '',
    title: '#',
    thumbnail: {
      src: getPlaceholderGatsbyImg(),
      alt: '',
      width: defaultWidth,
      height: defaultHeight,
    },
  };

  if (latestCreditCardGuidesPost[0]) {
    latestPopularArticle = getPopularArticleData(latestCreditCardGuidesPost[0]);
  }

  const postsGuides = creditCardGuidesPage?.ccGuides?.ccGuideArticles;
  const popularGuides = creditCardGuidesPage?.popularGuides?.popularArticles;

  let guidesToTransform: Queries.WpPost[] = [];
  let popularToTransform: Queries.WpPost[] = [];

  if (postsGuides && Array.isArray(postsGuides)) {
    guidesToTransform = postsGuides;
  }
  if (popularGuides && Array.isArray(popularGuides)) {
    popularToTransform = popularGuides;
  }

  const articlesGuidesExtracted = getArticles(guidesToTransform);
  const articlesPopularExtracted = getArticles(popularToTransform);

  const topCreditCardGuides = creditCardGuidesPage?.ccGuides;
  const featuredArticleExtracted = {
    link: creditCardGuidesPage?.popularGuides?.featuredPost?.uri || '',
    title: creditCardGuidesPage?.popularGuides?.featuredPost?.title || '#',
    thumbnail: {
      src: creditCardGuidesPage?.popularGuides?.featuredPost?.featuredImage?.node?.sourceUrl
        ? creditCardGuidesPage.popularGuides.featuredPost.featuredImage.node.sourceUrl
        : getPlaceholderGatsbyImg(),
      alt: creditCardGuidesPage?.popularGuides?.featuredPost?.title || '',
      width: creditCardGuidesPage?.popularGuides?.featuredPost?.featuredImage?.node?.width || defaultWidth,
      height: creditCardGuidesPage?.popularGuides?.featuredPost?.featuredImage?.node?.height || defaultHeight,
    },
  };
  const popularGuidesExtracted = creditCardGuidesPage?.popularGuides;
  const bestCardsExtracted = creditCardGuidesPage?.ccGuidesByType;
  const cardGuidesIssuerExtracted = creditCardGuidesPage?.ccGuidesByIssuer;
  const categoryNavExtracted =
    creditCardGuidesPage?.categoryNavFields?.categoriesAll?.map((item) => {
      return {
        title: item?.title || '',
        subTitle: item?.subTitle || '',
        link: item?.link || '',
        text: item?.text || '',
      };
    }) || [];

  const topCCPostsToShow =
    articlesGuidesExtracted.length < 4 ||
    topCreditCardGuides?.ccGuideDisplayArticles === 'Automatic'
      ? topCreditCardGuidesDynamicArticles
      : articlesGuidesExtracted;

  if (featuredArticleExtracted.link !== '' && featuredArticleExtracted.title !== '#') {
    latestPopularArticle = featuredArticleExtracted;
  }

  return (
    <CreditCardGuides
      pageHeader={{
        title: 'Credit Card Guides',
        breadCrumbs: [
          {
            link: '/',
            text: 'Home',
          },
          {
            link: '/credit-cards/',
            text: 'Credit Cards',
          },
          {
            link: '/credit-cards/guides/',
            text: 'Credit Card Guides',
          },
        ],
      }}
      bestCardsByType={{
        header: {
          title: bestCardsExtracted?.ccGuidesTypeTitle || '',
          moreLink: {
            link: bestCardsExtracted?.ccGuidesTypeViewAllLink || '',
            text: bestCardsExtracted?.ccGuidesTypeViewAllText || '',
          },
        },
        featuredCategoryNavItem: {
          label: bestCardsExtracted?.ccGuidesTypeFeaturedTitle || '',
          subLabel: bestCardsExtracted?.ccGuidesTypeFeaturedText || '',
          link: bestCardsExtracted?.ccGuidesTypeFeaturedLink || '',
        },
        categoryNavItems:
          bestCardsExtracted?.ccGuidesTypeBoxItems?.map((item) => {
            return {
              link: item?.ccTypeItemLink || '',
              label: item?.ccTypeItemTitle || '',
              iconTop: { src: item?.ccTypeItemIcon?.sourceUrl ? replaceDomainWithSiteDomain(item.ccTypeItemIcon.sourceUrl) : getPlaceholderGatsbyImg() },
            };
          }) || [],
      }}
      cardGuidesByIssuer={{
        header: {
          title: cardGuidesIssuerExtracted?.issuerTitle || '',
          moreLink: {
            text: cardGuidesIssuerExtracted?.viewAllIssuerText || '',
            link: cardGuidesIssuerExtracted?.viewAllIssuerLink || '',
          },
        },
        categoryNavItems:
          cardGuidesIssuerExtracted?.issuerBoxes?.map((item) => {
            return {
              link: item?.issuerLink?.uri || '',
              asset: {
                src:item?.issuerIcon?.sourceUrl ? replaceDomainWithSiteDomain(item.issuerIcon.sourceUrl) : getPlaceholderGatsbyImg(),
                alt: item?.issuerIcon?.altText || '',
              },
            };
          }) || [],
      }}
      personalCreditCardGuides={{
        articles: topCCPostsToShow,
        moreLink: {
          link: topCreditCardGuides?.ccLink || '',
          text: topCreditCardGuides?.ccLinkText || '',
        },
        isScrollable: true,
        title: topCreditCardGuides?.ccTitle || '',
        subTitle: topCreditCardGuides?.ccSubtitle || '',
      }}
      businessCreditCardGuides={{
        articles: businessCreditCardGuidesDynamicArticles,
        moreLink: {
          link: categoryNavExtracted[0]?.link,
          text: categoryNavExtracted[0]?.text || '',
        },
        title: categoryNavExtracted[0]?.title,
        isScrollable: true,
        subTitle: categoryNavExtracted[0]?.subTitle || '',
      }}
      popularGuides={{
        asideTitle: popularGuidesExtracted?.popularTitle || '',
        articles: articlesPopularExtracted,
        featuredArticle: {
          ...latestPopularArticle,
          category: {
            link: popularGuidesExtracted?.featuredPopularLink || '',
            text: popularGuidesExtracted?.featuredPopularCategory || '',
          },
        },
      }}
    />
  );
};

export const getPopularArticleData = (post: Queries.WpPost): IArticleExcerpt => {
  return {
    link: post?.uri || '',
    title: post?.title || '#',
    thumbnail: {
      src: post?.featuredImage?.node?.sourceUrl
        ? post.featuredImage.node.sourceUrl
        : getPlaceholderGatsbyImg(),
      alt: post?.title || '',
      width: post?.featuredImage?.node?.width || defaultWidth,
      height: post?.featuredImage?.node?.height || defaultHeight,
    },
  };
};
