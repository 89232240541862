import React, { FC } from 'react';
import { ICreditCardGuides } from './CreditCardGuides.def';
import PageHeader from '../../../PageHeader/PageHeader';
import SquareBrowser from '../../../SquareBrowser/SquareBrowser';
import FullFeatured from '../../../FullFeatured/FullFeatured';
import NewCategoryNav from '../../../NewCategoryNav/NewCategoryNav';

const CreditCardGuides: FC<ICreditCardGuides> = ({
  pageHeader,
  personalCreditCardGuides,
  businessCreditCardGuides,
  popularGuides,
  bestCardsByType,
  cardGuidesByIssuer,
}) => {
  const scrollerSettings = {
    defaultTilesToShow: 4,
    responsive: [
      {
        breakpoint: 1600,
        tilesToShow: 3,
      },
      { breakpoint: 500, tilesToShow: 2 },
    ],
  };
  return (
    <section className="credit-card-guides">
      <PageHeader {...pageHeader} />
      <NewCategoryNav {...bestCardsByType} />
      <NewCategoryNav {...cardGuidesByIssuer} />
      <SquareBrowser {...personalCreditCardGuides} scrollerSettings={scrollerSettings} />
      <SquareBrowser {...businessCreditCardGuides} scrollerSettings={scrollerSettings} />
      <FullFeatured {...popularGuides} />
    </section>
  );
};

export default CreditCardGuides;
