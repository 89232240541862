import React, { FC } from 'react';
import { INewCategoryNav } from './NewCategoryNav.def';
import { renderItems } from '../../utils';
import ArrowRightIcon from '../../svgs/ArrowRightIcon';
import { Link } from 'gatsby';

const NewCategoryNav: FC<INewCategoryNav> = ({
  categoryNavItems,
  featuredCategoryNavItem,
  header,
  variant,
}) => {
  return (
    <div className={`category-nav ${variant === 'gray' ? 'grayBg' : ''}`}>
      <div className="container">
        {header && (
          <div className="blockHeader">
            <div className="titles">
              {header.title && <h2>{header.title}</h2>}
              {header.subTitle && <p>{header.subTitle}</p>}
            </div>
            {header.moreLink && (
              <a
                href={header.moreLink.link}
                className={`btn-standard ${header.subTitle && 'hasSubtitle'}`}
              >
                {header.moreLink.text ? header.moreLink.text : ''}
              </a>
            )}
          </div>
        )}
        <div className="category-nav-items-wrapper">
          {featuredCategoryNavItem && (
            <Link to={featuredCategoryNavItem.link} className="featured-category-nav-item">
              {featuredCategoryNavItem.label && (
                <h4 className="featured-category-nav-item__label">
                  {featuredCategoryNavItem.label}
                </h4>
              )}
              {featuredCategoryNavItem.subLabel && (
                <p className="featured-category-nav-item__sub-label">
                  {featuredCategoryNavItem.subLabel}
                </p>
              )}
              <ArrowRightIcon />
            </Link>
          )}
          <div className="category-nav-items">
            {renderItems(categoryNavItems, 'NewCategoryItem')}
          </div>
        </div>
        {header?.moreLink && (
          <div className="see-all-wrapper">
            <a href={header.moreLink.link} className="btn-standard btn-standard-mobile">
              {header.moreLink.text ? header.moreLink.text : ''}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewCategoryNav;
